@import url('https://fonts.googleapis.com/css2?family=Anton&display=swap');

.articleheader {
    font-size: 100px;
    width: 100%;
    display: flex;
    position: relative;
    align-items: flex-end;
    justify-content: center;
    mix-blend-mode: screen;

    span {
        --color: #e4447c;
        font-family: Impact, 'Anton', Haettenschweiler, 'Arial Narrow Bold', sans-serif;
        font-weight: 700;
        font-style: italic;
        display: block;
        position: absolute;
        margin-left: -400px;
        color: var(--color);
        box-shadow: "0 6px 20px rgba(0, 0, 0, 1)";

        &::before,
        &::after {
            content: attr(data-text);
            display: block;
            position: relative;
            padding: 10px;
            z-index: 1;
        }

        &::before {
            position: absolute;
            -webkit-text-stroke: 20px #121212;
            z-index: 0;
        }

        &:first-child {
            transform: translate(-50px, -50px);
        }

        &:last-child {
            --color: #12b1bf;
            transform: translate(70px, 70px);
            z-index: 2;
        }
    }
}
.rotated-background {
    position: relative;
    overflow: hidden;
    width: 100vw;
    height: 100vh;
  }
  
  .rotated-background::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100vh; /* Increase size to ensure it covers the viewport even when rotated */
    height: 100vw;
    background-image: url("https://storage.googleapis.com/geek-stack.appspot.com/misc/UATestBG.jpg");
    background-size: cover;
    background-position: center;
    filter:brightness(80%);
    transform: translate(-50%, -50%) rotate(90deg); /* Center and rotate */
    z-index: -1;
  }
  
  
#article01 .css-1evflyk {
    content: "";
    position: fixed;
    background: url(https://unionarena-tcg.com/jp/images/products/boosters/jjk-1/bg.jpg) no-repeat top;
    background-size: cover;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -2;
}

@keyframes cardShimmer {
    0% {
        transform: translate(-100%, -100%);
    }

    100% {
        transform: translate(100%, 100%);
    }
}

.card__shine {
    height: 100%;
    left: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    transform: translateZ(2px);
    width: 100%;
}

@media screen and (min-width: 900px) {
    .card__img-wrapper {
        height: 314.25px;
    }
}

@media screen and (max-width:899px) {
    .card__img-wrapper {
        height: 213.48px;
    }
}

.card__img-wrapper {
    position: relative;
    transform-style: preserve-3d;
    display: flex;
    justify-content: center;
}

.card__img,
.card__shine {
    border-bottom-left-radius: 4.25% 3%;
    border-bottom-right-radius: 4.25% 3%;
    border-top-left-radius: 4.35% 3%;
    border-top-right-radius: 4.25% 3%;
    overflow: hidden;
}

.card__shine-shimmer {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    animation: cardShimmer 3s linear infinite;
    mix-blend-mode: luminosity;
    transform: translate(-100%, -100%);
}

.card__shine-shimmer::after {
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.37), rgba(255, 255, 255, 0));
    content: "";
    height: 20%;
    left: -50%;
    opacity: 0.7;
    position: absolute;
    top: 40%;
    transform: rotate(-45deg);
    width: 200%;
}

.card__shine_alt {
    height: 100%;
    left: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    transform: translateZ(2px);
    width: 100%;
    border-bottom-left-radius: 4.25% 3%;
    border-bottom-right-radius: 4.25% 3%;
    border-top-left-radius: 4.35% 3%;
    border-top-right-radius: 4.25% 3%;
    overflow: hidden;
}

.card__shine-shimmer_alt {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    animation: cardShimmer 3s linear infinite;
    mix-blend-mode: luminosity;
    transform: translate(-100%, -100%);
}

.card__shine-shimmer_alt::after {
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.37), rgba(255, 255, 255, 0));
    content: "";
    height: 20%;
    left: -50%;
    opacity: 0.7;
    position: absolute;
    top: 40%;
    transform: rotate(-45deg);
    width: 200%;
}
.formContainer {
    background-repeat: no-repeat;
    background-position: 50% 100%;
    background-size:cover;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
@media (max-width: 599px){
    .formWrapper{
        width:50vw;
        padding:20px 0px;
    }
}
.formWrapper {
    background-color: white;
    padding: 20px 60px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;

    .logo {
        color: #2E003E;
        font-size: 15px;
        font-weight: bold;
    }

    .title {
        color: #2E003E;
        font-size: 12px;
    }

    form {
        display: flex;
        flex-direction: column;
        gap: 15px;

        input {
            padding: 15px;
            border: none;
            width: 250px;
            border-bottom: 1px solid #a7bcff;

            &::placeholder {
                color: rgb(175, 175, 175);
            }
        }

        button {
            background-color: #2E003E;
            color: white;
            padding: 10px;
            font-weight: bold;
            border: none;
            cursor: pointer;
        }

        label {
            display: flex;
            align-items: center;
            gap: 10px;
            color: #5d5b8d;
            font-size: 12px;
            cursor: pointer;
        }
    }

    p {
        color: #5d5b8d;
        font-size: 12px;
        margin-top: 12px;
    }
}

.hide-scrollbar::-webkit-scrollbar {
    display: none;
}

.hide-scrollbar {
    -ms-overflow-style: none;
}

.image-responsive {
    width: 200px;
    height: 281.235px;
}

@media (max-width: 599px) {
    .image-responsive {
        width: 100px;
        height: 140.618px;
    }
}

@media (max-width: 1300px) {
    .mobile-hidden {
        display: none;
    }
}
/*Homepage*/
.fc-list-day-cushion{
    background-color: #240056 !important;
}
.fc-list-event:hover{
    color:#121212 ;
}
.fc-list-event{
    background-color: #26252d;
    color:#f2f3f8;
}
.fc-listYear-view, .fc-list-day th, .fc-event td{
    border-color:transparent !important;
}

:root{
    --fc-border-color: transparent !important;
}

.fc-list-event-time {
    display:none; /* This will hide the "all-day" text */
}
.fc-list-event-graphic{
    display:none;
}
.fc-list-event-title {
    display:flex;
    align-items: center;
    flex: 0 0 auto;
}
.fc-list-event-title div{
    height: 40px !important;
}
.fc-toolbar-title{
    margin-left: 30px !important;
}
/*UADecklistCardButton*/
.uatags-effect {
    padding: 5px;
    border-radius: 5px;
    font-size: 12px;
    font-weight: 900;
    margin-right: 10px;
    background-color: #7c4fff;
    color: #f2f3f8;
    transition: all 0.2s ease-in-out;
  }
  
  .uatags-effect:hover {
    background-color: #001146;
    transition: all 0.2s ease-in-out;
  }
/*HomepageDashboard*/
.socialmediabtn{
    cursor: pointer;
    width:40px;
    height:40px;
    display:flex;
    justify-content:center;
    align-items:center;
    background-color:#f2f3f8;
    border-radius:10px;
    transition: 0.2s ease-in-out;
}
#socialmediabtnlarge{
    width:60px;
    height:60px;
}
.socialmediabtn:hover{
    border-radius: 20px;
    transition: 0.2s ease-in-out;
}
#socialmediabtnlarge:hover{
    border-radius: 30px;
    transition: 0.2s ease-in-out;
}
.MuiInput-underline{
    color:'#f2f8fc';
}

.adsbygoogle {
    min-width: 300px; /* Or another appropriate value */
    min-height: 250px; /* This will depend on the ad format */
}

