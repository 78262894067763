body,
html {
  overflow-x: hidden;
  overflow-y:hidden;
  margin: 0;
  padding: 0;
  background-color:#101418;
  scroll-behavior: smooth;
  overscroll-behavior: none;
}

#app-container {
  max-width: 100%;
  overflow-x: hidden;
}

/* Remove outline for all focusable elements */
*:focus {
  outline: none;
}

/* If you're using buttons, sometimes the following might also be necessary for certain browsers */
button::-moz-focus-inner {
  border: 0;
}
